@media (min-width: 991px) {
  .find-us-info {
    padding-top: 20px;
    font-size: 17px;
  }
  .find-us-row {
    max-width: 800px;
  }
}

@media (max-width: 991px) {
  .find-us-info {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .find-us-info {
    font-size: 15px;
    margin-left: 35px;
  }
  .find-us-row {
    max-width: 400px;
  }
}

@media (max-width: 400px) {
  .find-us-info {
    font-size: 15px;
    margin-left: 13px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .find-us-row {
    max-width: 400px;
  }
  .copy-right {
    font-size: 13px;
  }
}
