@import url("https://fonts.googleapis.com/css2?family=Neonderthaw&family=Noto+Sans:wght@400;500&family=Source+Serif+Pro:ital,wght@0,200;0,400;1,200&display=swap");

@media (max-width: 499px) {
  .brand {
    font-size: 30px;
    font-weight: 400;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
  .brand-info {
    font-size: 13px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 500px) {
  .brand {
    font-size: 60px;
    font-weight: 400;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
  .brand-info {
    font-size: 20px;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 768px) {
  .brand {
    font-size: 60px;
    font-weight: 400;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
  .brand-info {
    font-size: 20px;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
}

@media (min-width: 991px) {
  .brand {
    font-size: 120px;
    font-weight: 300;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
  .brand-info {
    font-size: 40px;
    text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.6);
  }
}
