#pizzaFilm {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .pj-img {
    width: 100%;
  }
  #about {
    max-width: 1200px;
  }
  .title {
    margin-top: 0;
  }
}

@media (min-width: 500px) {
  .pj-img {
    width: 100%;
    display: block;
    margin: auto;
  }
  #about {
    margin: auto;
    padding: 0 9%;
  }
}

@media (max-width: 499px) {
  .pj-img {
    width: 100%;
    display: block;
    margin: auto;
  }

  .title {
    margin-top: 12px;
  }
  #about {
    margin: auto;
    padding: 0 9%;
  }
}
