.nav-bar {
  background-color: black !important;
  color: white;
  position: sticky !important;
  top: 0;
  z-index: 100;
}

.header__logo {
  height: 80px;
  opacity: 0.9;
  background-color: black !important ;
}

.header__btn {
  padding: 3px;
  border: 1px solid white !important;
  background-color: black !important;
  color: white;
  border-radius: 3px;
  width: 100%;
  /* background-color: white; */
  /* color: white; */
}

.nav-link {
  color: white !important;
}

@media (max-width: 500px) {
  .navbar-dark .navbar-nav .nav-link {
    font-size: 16px;
  }
  .header__number {
    font-size: 17px;
    font-weight: 800;
  }
  .header__btn {
    background-color: none;
  }
  .header__logo {
    height: 55px;
    opacity: 0.9;
    background-color: black !important ;
  }
}
