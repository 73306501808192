.manoushPhoto {
  height: 30vh;
  width: 100%;
  display: block;
  min-height: 80px;
}
@media (min-width: 568px) {
  .manoushPhoto {
    height: 50vh;
    width: 100%;
    display: block;
  }
}

@media (min-width: 991px) {
  .manoushPhoto {
    height: 70vh;
    width: 100%;
    display: block;
  }
}
