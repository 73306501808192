.container {
  width: 90%;
  margin: auto;
}

.order__hero {
  display: flex;

  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 50px;

  min-height: 50vh;
  padding: 50px;
}

.uberEatsPhoto {
  padding-top: 12px;
  /* padding-bottom: 12px; */
}

.doorDashPhoto {
  padding-top: 20px;
  padding-bottom: 10px;
}

.card__card {
  padding-top: 500px;
}

@media (max-width: 500px) {
  .card__card {
    margin-top: 20px;
    margin: 30px;
    padding: 10px;
  }
  /* .uber {
    margin-top: 100px;
  } */
}

@media (min-width: 501px) {
  .card__card {
    margin-top: 20px;
    margin: 40px;
    padding: 20px;
  }
}

@media (mix-width: 768px) {
  .order__hero {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-top: 100px;
    min-height: 50vh;
  }
  .card__card {
    margin-top: 20px;
  }
}

@media (min-width: 990px) {
  .card__card {
    max-width: 220px;
  }
}
