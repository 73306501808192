.menuImg {
  height: 40vh;
  width: 100%;
  display: block;
  min-height: 80px;
}

.mainMenu {
  width: 80%;
  padding-top: 10px;
  margin: auto;
  margin-top: -130px;
  min-height: 100vh;
  background: rgb(255, 255, 255);
  box-sizing: border-box;
  position: relative;
}

.heading__menu {
  padding: 10px;
  color: rgb(0, 0, 0);
  font-size: 50px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
}

.name__menu {
  padding: 10px;
  display: flex;
  justify-content: center;
  margin: 20px;
  flex-wrap: wrap;
}

.name__menu > button {
  font-size: 20px;
  border: none;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-decoration: underline;
  padding: 18px;
  font-weight: bold;
}

button:hover {
  color: #fdc600;
}

.image__menu {
  width: 100%;
}

@media (min-width: 991px) {
  .menuImg {
    height: 70vh;
    width: 100%;
    display: block;
  }
}

@media (max-width: 500px) {
  .name__menu > button {
    font-size: 17px;
    padding-top: 0;
  }

  .heading__menu {
    font-size: 32px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
